
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import MaterialSelect from '@/components/material-select/index.vue'
import { PageMode } from '@/utils/type'
import {
	apiSdvideoSourceLists,
	apiSdvideoModelPoseAdd,
	apiSdvideoModelPoseDetail,
	apiSdvideoModelPoseEdit,
} from '@/api/sdvideo'
@Component({
    components: {
        MaterialSelect
    }
})
export default class ImgCategoryEdit extends Vue {
	@Prop() id?: number
    @Prop({
        default: ''
    })
    title!: string //弹窗标题
    @Prop({
        default: '660px'
    })
    width!: string | number //弹窗的宽度
    @Prop({
        default: '20vh'
    })
    top!: string | number //弹窗的距离顶部位置
    /** S Data **/
    visible = false
    $refs!: {
        formRef: any
    }
	
    form = {
		id: 0,
        model_id: '', 
        uri: '',
        name: '',
    }

	// type
	model_list = []
    getModelList() {
        apiSdvideoSourceLists({ page_type: 1 , type:"img"}).then((res: any) => {
            res?.lists.forEach((item: any) => {
                item.sons &&
                    item.sons.forEach((sitem: any) => {
                        delete sitem.sons
                    })
            })
			let all_arr:any = [{id:'', name:'请选择'}]
			this.model_list = all_arr.concat(res?.lists)
        })
    }

    // 表单验证
    formRules = {
        uri: [
            {
                required: true,
                message: '请上传图片',
                trigger: ['blur', 'change']
            }
        ]
    }
    /** E Data **/

    // 详情
    getDetail() {
        apiSdvideoModelPoseDetail({
            id: this.form.id
        })
            .then((res: any) => {
                this.form.uri = res.uri
                this.form.model_id = res.model_id
				this.form.name = res.name
            })
            .catch((err: any) => {
                console.log('err', err)
            })
    }

    // 新增
    onModelPoseAdd() {
        apiSdvideoModelPoseAdd(this.form)
            .then(() => {
                this.$emit('refresh')
                this.close()
            })
            .catch((err: any) => {
                console.log('err', err)
            })
    }

    // 编辑
    onModelPoseEdit() {
        apiSdvideoModelPoseEdit({
            ...this.form,
            id: this.id
        })
            .then(() => {
                this.$emit('refresh')
                this.close()
            })
            .catch((err: any) => {
                console.log('err', err)
            })
    }

    // 提交
    onSubmit() {
        this.$refs.formRef.validate((valid: any) => {
            if (!valid) {
                return
            }
            if (this.form.id > 0) {
                this.onModelPoseEdit()
            } else {
                this.onModelPoseAdd()
            }
        })
    }

    // 弹窗打开触发
    onTrigger() {
        this.visible = true
		this.getModelList()
        if (this.id) {
			this.form.id = this.id
            this.getDetail()
        }		
    }

    // 关闭弹窗
    close() {
        this.visible = false
    }
    /** E Methods **/
}
