
import { Component, Vue, Watch } from "vue-property-decorator";
import { apiSdvideoSourceLists,apiSdvideoModelPoseLists,apiSdvideoModelPoseDel } from "@/api/sdvideo";
import MaterialSelect from "@/components/material-select/index.vue";
import { RequestPaging } from '@/utils/util'
import { PageMode } from '@/utils/type'
import LsDialog from '@/components/ls-dialog.vue'
import LsPagination from '@/components/ls-pagination.vue'
import SdvideoModelPoseEdit from '@/components/sdvideo/sdvideo-model-pose-edit.vue'

@Component({
    components: {
        LsDialog,
        LsPagination,	
        MaterialSelect,
		SdvideoModelPoseEdit
    },
})
export default class Source extends Vue {

    // 分页
    pager: RequestPaging = new RequestPaging()

    // 搜索表单
    searchForm = {
        model_id:'',
    }
	// type
	model_list = []
    getModelList() {
        apiSdvideoSourceLists({ page_type: 1 , type:"img"}).then((res: any) => {
            res?.lists.forEach((item: any) => {
                item.sons &&
                    item.sons.forEach((sitem: any) => {
                        delete sitem.sons
                    })
            })
			let all_arr:any = [{id:'', name:'请选择'}]
			this.model_list = all_arr.concat(res?.lists)
        })
    }
    /** S Methods **/

    // 获取列表
    getList() {
        this.pager
            .request({
                callback: apiSdvideoModelPoseLists,
                params: {...this.searchForm},
            })
            .catch((err: any) => {
                console.log('err', err)
            })
    }

    // 删除
    onSourceDel(row: any) {     
	   apiSdvideoModelPoseDel({
            id: row.id
        }).then(() => {
            this.getList()
        })
    }

    // 搜索
    onSearch() {
        this.pager.page = 1
        this.getList()
    }

    /** E Methods **/

    /** S Life Cycle **/
    created() {
        this.getList()
		this.getModelList()
    }

    /** E Life Cycle **/

}
